export const UI_MODAL_PROPS = {
  limitHeight: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'center',
  },
  rightButton: String,
  rightButtonGmt: String,
  rightButtonDataCy: String,
  keepOpen: Boolean,
  closeOthers: {
    type: Boolean,
    default: true,
  },
  title: String,
  cyTitle: String,
  overlayClass: [String, Array],
  wrapperClass: [String, Array],
  onResizeListener: String,
  showHeader: {
    type: Boolean,
    default: true,
  },
  containerClass: [String, Array],
  headerClass: [String, Array],
  bodyClass: [String, Array],
  modalBodyStyle: String,
  footerClass: [String, Array],
  closeText: {
    type: Boolean,
    default: false,
  },
  modalName: {
    type: String,
    required: false,
  },
  hideFooter: Boolean,
  showCloseButton: {
    type: Boolean,
  },
  closeButtonClass: [String, Array],
  visible: {
    type: Boolean,
    default: false,
  },
}
